import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IconButton } from 'react-ui-kit-exante';

import { TFileWithId } from '~/api/requests/requests.types';
import {
  EntryColumn,
  EntryPage,
  EntryScreenWrapper,
  EntrySection,
} from '~/components/EntryScreen';
import { EntryScreenHeader } from '~/components/EntryScreen/EntryScreenHeader';
import { ValueRow } from '~/components/ValueRow/ValueRow';
import { PATHS } from '~/router';
import { TParams } from '~/router/router.types';
import { TLocationState } from '~/types/location';
import { paramsSerializer } from '~/utils/apiRequest/helpers';
import { getDirtyValues } from '~/utils/forms/getDirtyValues';
import { APP_INSTANCE } from '~/utils/getCrmInstanceName';
import { getFormField } from '~/utils/getFormField';

import {
  REQUEST_FORM_VALIDATION_SCHEMA,
  STATE_SOLVED,
} from './RequestEntry.constants';
import {
  getDefaultFormValues,
  prepareFields,
  prepareMultiselectField,
  prepareStatesOptions,
} from './RequestEntry.helpers';
import {
  StyledRequestEntryBtnLoader,
  StyledRequestEntryViewLink,
} from './RequestEntry.styled';
import {
  TDynamicFields,
  TOptions,
  TRequestEntry,
  TRequestForm,
  TRowForm,
} from './RequestEntry.types';

export const RequestEntry = ({
  request,
  optionsForm,
  onEditRequest,
  onSendRequest,
  onUploadFiles,
  onDeleteFile,
  onDownloadFile,
  states,
  isProcessing,
  isSendLoading,
  isEditLoading,
}: TRequestEntry) => {
  const [stateOptions, setStateOptions] = useState<TOptions[]>();
  const [isDisabledSentBtn, setIsDisabledSentBtn] = useState(true);
  const { id: requestId } = useParams<TParams>();
  const navigate = useNavigate();
  const location = useLocation();

  const { requestParams } = (location.state as TLocationState) || {};

  const requestTypeId = request?.type.id;

  const fields: TDynamicFields[] = useMemo(
    () => prepareFields(requestTypeId, optionsForm, stateOptions, isProcessing),
    [requestTypeId, optionsForm, stateOptions],
  );

  const defaultValues = getDefaultFormValues(request, fields);

  const methods = useForm<TRequestForm>({
    defaultValues,
    resolver: yupResolver(REQUEST_FORM_VALIDATION_SCHEMA),
  });

  const {
    getValues,
    formState: { dirtyFields, isDirty },
    watch,
    handleSubmit,
    reset,
  } = methods;

  watch();

  const formData = getDirtyValues(dirtyFields, getValues());

  const {
    state_obj_id: stateIdFromForm,
    received_from: receivedFrom,
    access_level: accessLevel,
    assigned_to: assignedTo,
  } = getValues();

  const isSendMail = optionsForm?.recordTypes?.filter(
    (item) => item?.id === requestTypeId,
  )[0]?.send_mail;

  useEffect(() => {
    const preparedStatesOptions = prepareStatesOptions(states, requestTypeId);

    setStateOptions(preparedStatesOptions as TOptions[]);
  }, [requestTypeId, states]);

  useEffect(() => {
    reset(defaultValues);
  }, [request]);

  const handleFileAppend = (payload: TFileWithId[]) => {
    return onUploadFiles({
      files: payload.filter((file) => !file?.id),
      id: requestId,
    });
  };

  const onSave = async () => {
    const data = { ...request };

    if ((formData.files as TFileWithId[])?.length > 0) {
      await handleFileAppend(formData.files as TFileWithId[]);
    }

    const prepareData = () => {
      const body: Record<string, unknown> = {
        ...data,
        ...formData,
        type_id: requestTypeId,
        tagvalues: [],
      };

      delete body?.files;

      if (requestTypeId) {
        body.type = {
          id: requestTypeId,
          is_sensitive: false,
          send_mail: false,
          show_tags: false,
        };
      }

      if (receivedFrom?.length > 0) {
        body.received_from = prepareMultiselectField(
          optionsForm?.receivedFrom,
          receivedFrom,
        );
      }

      if (accessLevel?.length > 0) {
        body.access_level = prepareMultiselectField(
          optionsForm?.receivedFrom,
          accessLevel,
        );
      }

      if (assignedTo?.length > 0) {
        body.assigned_to = prepareMultiselectField(
          optionsForm.assigned,
          assignedTo,
        );
      }

      return body;
    };

    setIsDisabledSentBtn(false);

    return onEditRequest({
      id: requestId,
      data: prepareData(),
    });
  };

  const isDisabledSaveBtn = !isDirty || isProcessing;

  const saveBtn = () => {
    if (isEditLoading) {
      return <StyledRequestEntryBtnLoader />;
    }

    return (
      <IconButton
        label="Save"
        iconName="SaveIcon"
        iconColor="action"
        type="submit"
        iconSize={32}
        onClick={handleSubmit(onSave)}
        disabled={isDisabledSaveBtn}
      />
    );
  };

  const goToBack = (data: Record<string, unknown>) => {
    if (data) {
      navigate(PATHS.MONITORING);
    }
  };

  const onSend = async () => {
    const { data } = await onSendRequest({ id: requestId });

    goToBack(data);
  };

  const onSaveAndSend = async () => {
    const { data } = await onSave();

    goToBack(data);
  };

  const actions = useMemo(() => {
    if (!isSendMail) {
      return saveBtn();
    }

    if (isSendMail && stateIdFromForm !== STATE_SOLVED) {
      return (
        <>
          {saveBtn()}
          {isSendLoading ? (
            <StyledRequestEntryBtnLoader />
          ) : (
            <IconButton
              iconSize={32}
              label="Send"
              iconName="TransferIcon"
              iconColor="action"
              type="submit"
              disabled={isDisabledSentBtn || isProcessing}
              onClick={handleSubmit(onSend)}
            />
          )}
        </>
      );
    }

    if (isSendMail && stateIdFromForm === STATE_SOLVED) {
      return isProcessing ? (
        <StyledRequestEntryBtnLoader />
      ) : (
        <IconButton
          iconSize={32}
          label="Save & Send"
          iconName="TransferIcon"
          iconColor="action"
          type="submit"
          disabled={isDisabledSaveBtn}
          onClick={handleSubmit(onSaveAndSend)}
        />
      );
    }

    return null;
  }, [
    isSendMail,
    onSaveAndSend,
    isDisabledSaveBtn,
    isProcessing,
    stateIdFromForm,
    onSend,
    isDisabledSentBtn,
    isSendLoading,
    saveBtn,
  ]);

  const onCloseEntry = () => {
    const { sorting, limit, skip, page, ...rest } = requestParams || {};

    navigate(`${PATHS.MONITORING}?${paramsSerializer(rest)}`);
  };

  return (
    <FormProvider {...methods}>
      <form>
        <EntryScreenWrapper>
          <EntryScreenHeader
            title={`Monitoring request #${requestId}`}
            onClose={onCloseEntry}
            actions={[actions]}
          />
          <EntryPage>
            <EntryColumn>
              <EntrySection>
                {fields.map((item) => {
                  const {
                    editable,
                    name,
                    options,
                    title,
                    type: typeField,
                    isMultiple,
                    disabled,
                    isLoading,
                  } = item;
                  const value = request && request[name as TRowForm];

                  if (!editable && name === 'username') {
                    return (
                      <ValueRow
                        key={name}
                        label={title}
                        valueNode={
                          <StyledRequestEntryViewLink
                            href={`/${APP_INSTANCE}/client-${value}`}
                            target="_blank"
                            rel="noreferrer"
                            className="WithdrawalFormViewLink"
                          >
                            {value}
                          </StyledRequestEntryViewLink>
                        }
                      />
                    );
                  }

                  if (!editable && name === 'type') {
                    const typeId = (value as { id?: string })?.id;

                    return <ValueRow key={name} label={title} value={typeId} />;
                  }

                  if (name === 'summary' || name === 'subject') {
                    return null;
                  }

                  return getFormField({
                    label: title,
                    type: typeField,
                    name,
                    options: options || [],
                    size: 'medium',
                    isMultiple,
                    disabled,
                    isLoading,
                    onDeleteFile,
                    onDownloadFile,
                  });
                })}
              </EntrySection>
            </EntryColumn>
            <EntryColumn>
              <EntrySection>
                {fields.map((item) => {
                  const { name, options, title, type: typeField } = item;
                  if (name === 'summary' || name === 'subject') {
                    return getFormField({
                      label: title,
                      type: typeField,
                      name,
                      options,
                      size: 'medium',
                      disabled: isProcessing,
                    });
                  }

                  return null;
                })}
              </EntrySection>
            </EntryColumn>
          </EntryPage>
        </EntryScreenWrapper>
      </form>
    </FormProvider>
  );
};
