import { eq } from 'lodash';
import {
  MouseEvent,
  ReactChild,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTranslator } from '../../../contexts/TranslationContext';
import { IconButton } from '../../Button/IconButton';
import { Checkbox } from '../../Inputs/Checkbox';
import { SearchInput } from '../../Inputs/SearchInput/SearchInput';
import { typedMemo } from '../helpers/typedMemo';
import { getKeysByColumns } from '../hooks/useColumnsKeys/utils';
import { TColumnResetType } from '../types';

import {
  StyledButtonGroup,
  StyledColumnSelectList,
  StyledColumnSelectListItem,
  StyledColumnSelectPopover,
  StyledColumnSelectWrapper,
  StyledIconButton,
} from './ColumnSelect.styled';
import { TColumnSelectProps } from './ColumnSelect.types';

const POPOVER_ANCHOR_ORIGIN = {
  vertical: 'bottom',
  horizontal: 'right',
} as const;

const POPOVER_TRANSFORM_ORIGIN = {
  vertical: 'top',
  horizontal: 'right',
} as const;

export const ColumnSelectComponent = <Data extends object>({
  columns,
  selected,
  onChange,
  onReset,
  isHiddenColumnSelectControls,
  translator: customTranslator,
}: TColumnSelectProps<Data>) => {
  const { t } = useTranslator(customTranslator);

  const [resetState, setResetState] = useState<TColumnResetType | null>(null);
  const [selectedKeys, setSelectedKeys] = useState(new Set(selected));
  const selectedKeysRef = useRef(selectedKeys);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    selectedKeysRef.current = selectedKeys;
    setAnchorEl(event.currentTarget);
    setResetState(null);
  };

  const { current: allColumns } = useRef(
    columns.filter((column) => column.accessor && !column.hideInMenu),
  );

  const { current: allKeys } = useRef(getKeysByColumns<Data>(allColumns));

  const handleClose = () => {
    setAnchorEl(null);
    setSearchValue('');

    if (resetState) {
      onReset(resetState);
    } else if (!eq(selectedKeysRef.current, selectedKeys)) {
      onChange(Array.from(selectedKeys));
    }
  };

  const handleCheckboxChange = (key: string) => {
    const result = new Set(selectedKeys);
    setResetState(null);

    if (selectedKeys.has(key)) {
      result.delete(key);
    } else {
      result.add(key);
    }

    setSelectedKeys(result);
  };

  const open = Boolean(anchorEl);

  const columnsFiltered = useMemo(
    () =>
      columns.filter(
        (column) =>
          column.accessor &&
          !column.hideInMenu &&
          typeof column.Header === 'string' &&
          column.Header.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [columns, searchValue],
  );

  const handleResetAll = () => {
    setSelectedKeys(new Set(allKeys.filter((item): item is string => !!item)));
    setResetState('all');
  };

  const handleResetNone = () => {
    setSelectedKeys(new Set([]));
    setResetState('none');
  };

  const selectedRef = useRef(selected);

  const handleResetDefault = () => {
    onReset('default');
    setResetState('default');
    setTimeout(() => {
      setSelectedKeys(new Set(selectedRef.current));
    }, 100);
  };

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  return (
    <StyledColumnSelectWrapper className="ColumnSelect">
      <IconButton
        iconName="ListIcon"
        iconColor="secondary"
        iconSize={24}
        onClick={handleOpen}
        className="IconButton"
        data-test-id="table__head--column-button-open"
      />
      <StyledColumnSelectPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={POPOVER_ANCHOR_ORIGIN}
        transformOrigin={POPOVER_TRANSFORM_ORIGIN}
        className="ColumnSelectPopover"
        data-test-id="table__head--column-popover"
      >
        <SearchInput
          onChange={setSearchValue}
          inputProps={{
            fullWidth: true,
            className: 'SearchInput',
            placeholder: t('ui__table__colselect__search_placeholder'),
          }}
        />
        <StyledColumnSelectList className="ColumnSelectList">
          {columnsFiltered.map(({ id, required, Header }) => {
            return (
              <StyledColumnSelectListItem
                key={id}
                className="ColumnSelectListItem"
                data-test-id="table__head--column-checkbox"
              >
                <Checkbox
                  name={String(id)}
                  label={Header as ReactChild}
                  checked={selectedKeys.has(id as string)}
                  disabled={required}
                  onChange={() => handleCheckboxChange(String(id))}
                />
              </StyledColumnSelectListItem>
            );
          })}
        </StyledColumnSelectList>
        {!isHiddenColumnSelectControls && (
          <StyledButtonGroup variant="outlined" className="ButtonGroup">
            <StyledIconButton
              applyStates={false}
              aria-label={t('ui__table__colselect__btn_show_all')}
              className="IconButton"
              data-test-id="table__head--column-button-show-all"
              iconColor="primary"
              iconName="EyeIcon"
              iconSize={16}
              onClick={handleResetAll}
            />
            <StyledIconButton
              applyStates={false}
              aria-label={t('ui__table__colselect__btn_hide_all')}
              className="IconButton"
              data-test-id="table__head--column-button-hide-all"
              iconColor="primary"
              iconName="EyeDeactiveIcon"
              iconSize={16}
              onClick={handleResetNone}
            />
            <StyledIconButton
              applyStates={false}
              aria-label={t('ui__table__colselect__btn_reset')}
              className="IconButton"
              data-test-id="table__head--column-button-default"
              iconColor="primary"
              iconName="RotateCcwIcon"
              iconSize={16}
              onClick={handleResetDefault}
            />
          </StyledButtonGroup>
        )}
      </StyledColumnSelectPopover>
    </StyledColumnSelectWrapper>
  );
};

export const ColumnSelect = typedMemo(ColumnSelectComponent);
