import { FC } from 'react';
import { KitTheme, SuspendIcon } from 'react-ui-kit-exante';

import { EDepositStatuses } from '../api/deposits/deposits.types';
import { EWithdrawalsStatuses } from '../api/withdrawals/withdrawals.types';
import {
  CheckCircleIcon,
  CheckSquareOffsetIcon,
  CloudCheckIcon,
  CrossCircleIcon,
  PlusCircleIcon,
} from '../assets/icons';

export const colorsMap = (
  theme: KitTheme,
): Record<
  'withdrawal' | 'withdrawalTable' | 'deposit' | 'depositsTable',
  Record<string, string>
> => {
  return {
    withdrawal: {
      [EWithdrawalsStatuses.Active]: theme?.color?.typo?.active,
      [EWithdrawalsStatuses.OnHold]: theme?.color?.typo?.warning,
      [EWithdrawalsStatuses.Rejected]: theme?.color?.typo?.radical,
      [EWithdrawalsStatuses.Executed]: theme?.color?.typo?.action,
      [EWithdrawalsStatuses.Old]: theme?.color?.bg?.promo,
    },
    withdrawalTable: {
      [EWithdrawalsStatuses.Active]: theme?.color?.bg?.active,
      [EWithdrawalsStatuses.OnHold]: theme?.color?.bg?.radical,
      [EWithdrawalsStatuses.Rejected]: theme?.color?.bg?.warning,
      [EWithdrawalsStatuses.Executed]: theme?.color?.bg?.source,
      [EWithdrawalsStatuses.Old]: theme?.color?.bg?.primary,
    },
    deposit: {
      [EDepositStatuses.Active]: theme?.color?.typo?.active,
      [EDepositStatuses.OnHold]: theme?.color?.typo?.warning,
      [EDepositStatuses.FundsRejected]: theme?.color?.typo?.radical,
      [EDepositStatuses.BookedInBO]: theme?.color?.typo?.action,
      [EDepositStatuses.New]: theme?.color?.typo?.primary,
    },
    depositsTable: {
      [EDepositStatuses.Active]: theme?.color?.bg?.active,
      [EDepositStatuses.OnHold]: theme?.color?.bg?.radical,
      [EDepositStatuses.FundsRejected]: theme?.color?.bg?.warning,
      [EDepositStatuses.BookedInBO]: theme?.color?.bg?.source,
      [EDepositStatuses.New]: '',
    },
  };
};

export const mapIcons: Record<'withdrawal' | 'deposit', Record<string, FC>> = {
  withdrawal: {
    [EWithdrawalsStatuses.Old]: PlusCircleIcon,
    [EWithdrawalsStatuses.OnHold]: CheckSquareOffsetIcon,
    [EWithdrawalsStatuses.Executed]: CheckCircleIcon,
    [EWithdrawalsStatuses.Rejected]: CrossCircleIcon,
    [EWithdrawalsStatuses.Active]: CloudCheckIcon,
  },
  deposit: {
    [EDepositStatuses.New]: PlusCircleIcon,
    [EDepositStatuses.FundsRejected]: CrossCircleIcon,
    [EDepositStatuses.BookedInBO]: CloudCheckIcon,

    // todo replace icons
    [EDepositStatuses.Active]: CloudCheckIcon,
    [EDepositStatuses.OnHold]: SuspendIcon,
  },
};
