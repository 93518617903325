import { FC, SyntheticEvent, useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { IconButton, Tooltip } from 'react-ui-kit-exante';

import { useGetPermissionsQuery } from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import {
  EntryColumn,
  EntrySection,
  EntrySubSection,
} from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { getFormField } from '~/utils/getFormField';

import { isEmptyValues } from '../../../../utils/isEmptyValues';

import {
  cannotEditDepositText,
  formValuesNotChanged,
  getClientDetailsFields,
  getComplyRadarFields,
  getCryptoFields,
} from './DepositForm.constants';
import { StyledActionsRow } from './DepositForm.styled';
import { TDepositFormProps } from './DepositForm.types';
import { BankDetails } from './components/BankDetails';
import { StyledFormField } from './components/BankDetails/BankDetails.styled';
import { DepositFormContext } from './contexts/DepositFormContext';

export const DepositForm: FC<TDepositFormProps> = ({ deposit }) => {
  const { isEdit, setIsEdit, formNotEdited, isFormDisabled } =
    useContext(DepositFormContext);
  const { watch } = useFormContext();

  const { data: permissions } = useGetPermissionsQuery();
  const canChange = Boolean(permissions?.deposit_info.change_depositinfo);

  const onToggleEdit = (e: SyntheticEvent) => {
    e.preventDefault();
    setIsEdit(!isEdit);
  };

  const isEditDisabled = !canChange || isFormDisabled;

  const tooltipMessage = useMemo(() => {
    if (!canChange) {
      return cannotEditDepositText;
    }
    return '';
  }, [canChange]);

  const complyRadarFields = getComplyRadarFields(deposit);
  const cryptoFields = getCryptoFields(deposit);
  const currentFormValues = watch();

  return (
    <EntryColumn>
      <EntrySection
        title="Info"
        action={
          <StyledActionsRow className="ActionsRow">
            {isEdit ? (
              <>
                <Tooltip title={formNotEdited ? formValuesNotChanged : ''}>
                  <IconButton
                    title="Save deposit data"
                    label="Save"
                    type="submit"
                    iconName="SaveIcon"
                    iconColor="action"
                    iconSize={24}
                    disabled={formNotEdited}
                  />
                </Tooltip>
                <IconButton
                  title="close form editing"
                  type="button"
                  label="Cancel"
                  iconName="CloseIcon"
                  iconSize={24}
                  onClick={() => setIsEdit(!isEdit)}
                />
              </>
            ) : (
              <Tooltip title={tooltipMessage}>
                <IconButton
                  title="Open form editing"
                  type="button"
                  iconName="EditIcon"
                  iconColor="action"
                  disabled={isEditDisabled}
                  iconSize={24}
                  onClick={onToggleEdit}
                />
              </Tooltip>
            )}
          </StyledActionsRow>
        }
      >
        <EntrySubSection title="Client Details">
          {getClientDetailsFields(deposit, currentFormValues).map(
            ({
              name,
              type,
              title,
              optionsConfig,
              withCopyButton,
              valueForCopy,
              node,
            }) => {
              const value = deposit[name as keyof TDepositInfo];

              // because we need show account_id in Client Details section
              if (isEdit && name === 'account_id') {
                return (
                  <ValueRow
                    key={name}
                    label={title}
                    valueNode={
                      <StyledFormField className="FormField">
                        {getFormField({
                          type,
                          name,
                          options: optionsConfig?.options,
                        })}
                      </StyledFormField>
                    }
                  />
                );
              }
              return (
                <ValueRow
                  key={name}
                  label={title}
                  value={!node ? value : null}
                  valueNode={node}
                  withCopyButton={withCopyButton}
                  valueForCopy={valueForCopy}
                />
              );
            },
          )}
        </EntrySubSection>
        {complyRadarFields.length > 0 && (
          <EntrySubSection title="ComplyRadar">
            {complyRadarFields.map((field) => {
              return (
                <ValueRow
                  key={field.key}
                  label={field.label}
                  value={deposit?.[field.key as keyof TDepositInfo]}
                  withCopyButton
                />
              );
            })}
          </EntrySubSection>
        )}
        <BankDetails deposit={deposit} isEditMode={isEdit} />

        {cryptoFields.length > 0 && (
          <EntrySubSection title="Сrypto details">
            {cryptoFields.map((field) => {
              const value = deposit?.[field.name as keyof TDepositInfo];

              if (isEmptyValues(value, true) && typeof value !== 'boolean') {
                return null;
              }

              return (
                <ValueRow
                  key={field.name}
                  label={field.title}
                  value={value}
                  withCopyButton={field?.withCopyButton}
                />
              );
            })}
          </EntrySubSection>
        )}
      </EntrySection>
    </EntryColumn>
  );
};
