import { FC, useContext, useEffect, useRef } from 'react';

import { getFormField } from 'utils/getFormField';
import {
  useGetPermissionsQuery,
  useLazyGetCcyQuery,
  useLazyGetCountryAQuery,
} from '~/api';
import { TDepositInfo } from '~/api/deposits/deposits.types';
import { EntrySubSection } from '~/components/EntryScreen';
import { ValueRow } from '~/components/ValueRow';
import { autoScrollToSomething } from '~/utils/autoScrollToSomething';

import { SwiftField } from '../../../SwiftField';
import { getFields } from '../../DepositForm.helpers';
import { DepositFormContext } from '../../contexts/DepositFormContext';

import {
  StyledDepositFormContainer,
  StyledFormField,
} from './BankDetails.styled';
import { TBankDetailsProps } from './BankDetails.types';

export const BankDetails: FC<TBankDetailsProps> = ({ deposit, isEditMode }) => {
  const { hiddenFields, setIsFormDisabled } = useContext(DepositFormContext);

  const formRef = useRef<HTMLDivElement>(null);
  const { data: permissions } = useGetPermissionsQuery();

  const canChange = Boolean(permissions?.deposit_info.change_depositinfo);

  const [onCountryAFetch, stateCountryA] = useLazyGetCountryAQuery();
  const [onCcyFetch, stateCcy] = useLazyGetCcyQuery();

  useEffect(() => {
    if (isEditMode) {
      onCountryAFetch();
      onCcyFetch();

      autoScrollToSomething(formRef);
    }
  }, [isEditMode]);

  const countryA = {
    isLoading: stateCountryA?.isLoading,
    options: stateCountryA?.data,
  };

  const ccy = {
    isLoading: stateCcy?.isLoading,
    options: stateCcy?.data,
  };

  const bankDetailsFields = getFields({
    countryA,
    ccy,
    deposit,
    canChange,
    hiddenFields,
  });

  useEffect(() => {
    setIsFormDisabled(!bankDetailsFields.length);
  }, [bankDetailsFields]);

  if (!bankDetailsFields.length) {
    return null;
  }

  return (
    <EntrySubSection title="Bank Details">
      <StyledDepositFormContainer
        className="DepositFormContainer"
        ref={formRef}
      >
        {bankDetailsFields?.map(
          ({ name, title, type, formatter, optionsConfig, editable }) => {
            const value = deposit[name as keyof TDepositInfo];

            if (!isEditMode || editable === false) {
              return (
                <ValueRow
                  key={name}
                  label={title}
                  value={formatter ? formatter(value) : value}
                />
              );
            }

            if (name === 'swift' || name === 'bank_name') {
              return (
                <ValueRow
                  key={name}
                  label={title}
                  valueNode={
                    <SwiftField objectId={deposit.application} name={name} />
                  }
                />
              );
            }

            return (
              <ValueRow
                key={name}
                label={title}
                valueNode={
                  <StyledFormField className="FormField">
                    {getFormField({
                      type,
                      name,
                      isLoading: optionsConfig?.isLoading,
                      options: optionsConfig?.options,
                    })}
                  </StyledFormField>
                }
              />
            );
          },
        )}
      </StyledDepositFormContainer>
    </EntrySubSection>
  );
};
