// default displayed columns
export const DISPLAYED_COLUMNS_KEYS = [
  'id',
  'application',
  'client_id',
  'manager',
  'created',
  'changed_by',
  'type',
  'received_from',
  'assigned_to',
  'state',
  'subject',
  'summary',
  'files',
  'deadline',
];

export const REQUESTS_TABLE_NAME = 'requests';

export const REQUESTS_DATE_FIELDS = [
  'tag_date_monitoring / date of source',
  'field_deadline',
  'field_created',
  'tag_date_monitoring / date of opening',
];
