// todo fix any types

import { TConfirms } from '~/modules/confirms/components/Confirms/Confirms.types';
import { TChoiceOption } from '~/types/table';

export type TDepositInfo = {
  id: number;
  status: TTypeStatus;
  country_a: TType;
  country_a_iso: string;
  confirms_required: TType[];
  ccy: TType;
  uploads: TUpload[];
  account_id: string;
  app_crm_link: string;
  deposit_type: TType;
  date: string;
  username: string;
  client_name: string;
  responsible: string[];
  responsible_title: string;
  eur_amount_decimal: number;
  legal_entity: string;
  bank_details_confirm: TConfirm;
  third_party_confirm: TConfirm;
  amount_exceeding_onboarding_confirm: TConfirm;
  amount_exceeding_risk_confirm: TConfirm;
  first_deposit_confirm: TConfirm;
  available_transitions: TAvailableTransitions[];
  account_choices: string[];
  checkers: TChecker[];
  comply_radar_customer_id: string;
  comply_radar_account_id: string;
  actions: TAvailableTransitions[];
  hidden_edit_fields: string[];
  hidden_edit_fields_keys: string[];
  datetime: string;
  bank_service_created: any;
  name: string;
  iban: string;
  swift: string;
  ac_no: string;
  amount: string;
  bank_name: string;
  bank_deposit_id: string;
  detail: string;
  bank_ref_no: any;
  custody: string;
  custody_account: string;
  client_custody_account: string;
  bank_details_confirm_changed: any;
  third_party_confirm_changed: any;
  amount_exceeding_onboarding_confirm_changed: any;
  amount_exceeding_risk_confirm_changed: any;
  first_deposit_confirm_changed: any;
  source: string;
  is_removed: boolean;
  is_draft: boolean;
  comply_radar_id: string;
  comply_radar_result: any;
  application: number;
  account_manager?: {
    full_name?: string;
    username?: string;
  };
  client_type?: string;
  country_of_residence?: string;
  country_of_citizenship?: string;
  stages: TConfirms;

  // crypto fields
  internal_id?: string | null;
  crypto_network?: string | null;
  transaction_hash?: string | null;
  crypto_address_from?: string | null;
  crypto_address_to?: string | null;
};

export type TAvailableTransitions = {
  disabled: boolean;
  key: number;
  title: EDepositStatuses;
};

export type TType = {
  id: string;
  text: string;
};

export type TTypeStatus = {
  id: number;
  text: EDepositStatuses;
};

export type TConfirm = {
  key: string;
  value: boolean;
  title: string;
  readonly: boolean;
  changed: any;
};

export type TChecker = {
  name: string;
  message: string;
  result: boolean;
};

export type TUpload = {
  created: string;
  deposit_info: number;
  id: number;
  modified: string;
  name: string;
};

export enum EDepositStatuses {
  New = 'New',
  BookedInBO = 'Booked In BO',
  Active = 'Active',
  FundsRejected = 'Funds Rejected',
  OnHold = 'On-hold',
}

export type TUpdateDepositInfoParams = Partial<
  {
    uploads_ids: number[];
  } & Record<keyof TDepositInfo, string | number | boolean | null>
>;

export type TOptionsResponse = {
  type: string;
  choices: TChoiceOption[];
  text: string;
  fake: boolean;
  key: string;
  name: string;
};

export type TGetHiddenFieldsParams = {
  type: string;
};

export type TDepositType = 'wire' | 'bank_card' | 'crypto';

export type TPostDepositConfirmParams = { id: string; confirm: string };
